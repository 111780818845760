import { matchPath } from 'react-router-dom';
import { domainExceptionList } from 'services/utils/variables';
import storage from './storage';
import moment from 'moment';
import { Enums } from '../dropdown-enums';
import config from 'config';
import { DaylightTimezones } from './specific-conditions';

/**
 * @name @queryStringToJSONObject
 * @description convert query string  to a json object
 * @requires a valid query string withwith a question mark at start
 * @returns a json object
 * */
export const queryStringToJSONObject = query => {
  let qs = decodeURI(query.substring(1))
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"');
  return JSON.parse('{"' + qs + '"}');
};

/**
 * @name @getDomain
 * @description get domain of the current URL and remove if www is there
 * @requires Null
 * @returns domain name without www
 * */
export const getDomain = () => {
  const testDomain = process.env.REACT_APP_TEST_APIURL;
  let location = window.location;
  let hostname = location.hostname;
  if (hostname.split('.')[0] === 'www') {
    hostname = hostname.replace('www.', '');
  }
  let domain = `${location.protocol}//${hostname}/`;
  //check if the domain is in exception list or not and
  //if yes then assign test domain else assign the current location domain
  return replaceTestDomain(domain) ? testDomain : domain;
};

/**
 * @name @replaceTestDomain
 * @description check if array defined in variable.js has same domain as the parameter passed from getDomain
 * @requires domain in string from getDomain.
 * @returns boolean
 * */
export const replaceTestDomain = domain => {
  let hasSameDomain = domainExceptionList.find(expectionDomain => {
    return expectionDomain === domain;
  });
  return !!hasSameDomain;
};

/**
 * @name @setErrorClass
 * @description adding an error class on input field if its empty
 * @requires Two arguments required
 *               first as array [] which must have FormIK form object at postion 0
 *               (postion 1 is optional and must be a Boolian)
 *               second is the field name
 * @returns class name conditionally
 *              "has-value" if the input is not empty
 *              "is-invalid" if the field is not validated
 * */
export const setErrorClass = (payload, field) => {
  if (payload[0]?.values[field]) {
    return 'has-value';
  } else if (payload[0]?.touched[field] || payload[1]) {
    return 'is-invalid';
  } else {
    return '';
  }
};

/**
 * @name @matchDynamicRoute
 * @description match the dynammic path with the current page path
 * @requires Current defined route object
 * @returns Boolean (True if matched)
 * */
export const matchDynamicRoute = route => {
  const pathname = window.location.pathname;
  // match the path and extract dynmaic id params
  let matchRoutePathObj = matchPath(pathname, {
    path: route.path
  });
  if (matchRoutePathObj) {
    return replaceParamsId(route.path, matchRoutePathObj) || false;
  }
  return false;
};
export const removeElementFromArray = (array, property, matchedCondition) => {
  //check allTrackings either facebook is there already?
  const index = array.findIndex(x => x[property] === matchedCondition);
  let newArray = [];
  if (index === -1) {
    // if not found, do nothing..
    return array;
  } else {
    array.forEach((element, i) => {
      if (i !== index) {
        newArray.push(element);
      }
    });
    return newArray;
  }
};
export const setupTrackingPayload = (
  companyId,
  check,
  trackings,
  matchedCondition
) => {
  let allTrackings = Object.assign([], trackings);
  if (check) {
    // before push check the element is not exist.
    allTrackings = removeElementFromArray(
      allTrackings,
      'trackingType',
      matchedCondition
    );
    // then push
    allTrackings.push({
      companyId: companyId,
      trackingType: matchedCondition
    });
  } else {
    allTrackings = removeElementFromArray(
      allTrackings,
      'trackingType',
      matchedCondition
    );
  }
  return allTrackings;
};
export const replaceParamsId = (routeString, matchRoutePathObj) => {
  const id = process.env.REACT_APP_DYNAMIC_ROUTE_ID;
  // split and remove empty string
  let splittedRoute = routeString.split('/').filter(e => e !== '');
  if (matchRoutePathObj) {
    // replacing :id to dynamic id
    splittedRoute.forEach((e, i) => {
      if (e === ':' + id) {
        splittedRoute[i] = matchRoutePathObj.params[id];
      }
    });
    // form a valid path to match
    return '/' + splittedRoute.join('/');
  }
};

/**
 * @name @mapServerValuesToInitialValues
 * @description  map existing server data to initial values
 *               A promise that resolves only when there
 *               is atleast a value on server
 *               which is in initial values object
 * @requires Two arguments required
 *               first server values object
 *               second initial values object
 * @returns Null
 * */

export const mapServerValuesToInitialValues = (
  serverValuesObject,
  initialValuesObject
) => {
  let hasData = serverValuesObject && Object.keys(serverValuesObject).length;
  return new Promise(resolve => {
    let values = {};
    if (hasData) {
      for (let key in initialValuesObject) {
        if (serverValuesObject[key]) {
          values[key] = serverValuesObject[key];
        }
      }
    }
    if (Object.keys(values).length) resolve(values);
  });
};

/**
 * @name @camelCaseStringToNormalText
 * @description convert camel case string to normal to =>
 *              uppercase, lowercase or senetence case
 * @requires Required first argument string that needs to be converted
 *              optional second argument is case type
 * @returns converted string
 * */

export const camelCaseStringToNormalText = (
  string,
  letterCase,
  removeSpacesBtw = false
) => {
  let regex = removeSpacesBtw ? '$1' : ' $1';
  let normalText = string.replace(/([A-Z])/g, regex).replace(/^./, text => {
    return text;
  });
  if (letterCase === 'uppercase') {
    return normalText.toUpperCase();
  }
  if (letterCase === 'sentence') {
    let lowercase = normalText.toLowerCase().slice(1);
    return normalText.charAt(0).toUpperCase() + lowercase;
  }
  if (letterCase === 'heading') {
    let wordsArray = normalText.split(' ');
    let sentence = '';
    wordsArray.forEach(word => {
      if (word.toLowerCase() === 'id') {
        sentence += word.toUpperCase() + ' ';
      } else {
        sentence += (word[0]?.toUpperCase() + word.slice(1) + ' ').replace(
          'undefined',
          ''
        );
      }
    });
    return sentence;
  }
  return normalText;
};
/**
 * @param {string} str takes string like 'This is test' and returns 'thisIsTest'
 * @returns converted camel case string
 */
export const camelize = str => {
  return str
    ?.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const makeTitleFromKey = key =>
  key ? key.match(/[A-Z][a-z]+/g)?.join(' ') : '';

/**
 * @name @convertToNonMeridianTime
 * @description takes time in 24 hour e.g: "11:00 PM" and converts into "23:00"
 * @requires time12h in string "11:00 PM"
 * @returns string "23:00"
 * */
export const convertToNonMeridianTime = time12h => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
    hours = '00';
  }
  if (modifier && modifier.split(' ')[0] === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};

/**
 * @name @convertToMeridianTime
 * @description takes time in 24 hour e.g: "23:00" and converts into "11:00 PM"
 * @requires time12h in string "23:00"
 * @returns string "11:00 PM"
 * */
export const convertToMeridianTime = time24h => {
  let timeSplit = time24h.split(':'),
    hours,
    minutes,
    meridian;
  hours = timeSplit[0];
  minutes = timeSplit[1];
  if (hours > 12) {
    meridian = 'PM';
    hours -= 12;
  } else if (hours < 12) {
    meridian = 'AM';
    if (hours === 0) {
      hours = 12;
    }
  } else {
    meridian = 'PM';
  }
  return hours + ':' + minutes + ' ' + meridian;
};

/**
 * String Substitution is same as ECMA6 Substitution,
 * Usage: this.Name = 'Petter'; this.Join = 'join';
 * ApexChat.stringSubstitution('Hello @{Name}, you are requested to @{Join}', this); // "Hello John, you are requested to join"
 * @param {any} completeString
 * @param {any} obj
 */
export const stringSubstitution = (tempStr, obj) => {
  tempStr = tempStr.replace(/@{(.+?)}/g, "'$1'");
  let arr = tempStr.split("'");
  for (let i = 0; i < arr.length; i++) {
    if (obj[arr[i]]) {
      arr[i] = obj[arr[i]];
    }
  }
  return arr.join('');
};

export const getEnumValue = (enumArray, value) => {
  return enumArray.filter(e => e.value === value)[0]['name'];
};

export const removeKeysFromLS = (keys = []) => {
  // var head
  keys.forEach(element => {
    storage.deleteItem(element);
  });
};
export const removeFromCookies = (keys = []) => {
  keys.forEach(element => {
    setCookie(element, '', -3);
  });
};

export const getCookie = cname => {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (cname, cvalue, exdays = 1, cdomain = '') => {
  let d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  let cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  if (cdomain) cookie = `${cookie}; domain=${cdomain};`;
  document.cookie = cookie;
};

export const setCookieForSpecificMinutes = (cname, cvalue, minutes = 1) => {
  let d = new Date();
  d.setTime(d.getTime() + minutes * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

// remove support bot related cookies only
export const removeSupportBotCookies = () => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const key = cookies[i];
    if (key.includes('livechat_') || key.includes('apexchat_')) {
      setCookie(key.trim(), '', -3);
    }
  }
};

// A utility function as a better replacement for setTimeOut:
// keeps checking on a boolean condition passed as a 2nd arg
// and then only runs a func after the condition is found to be true
export const RunAsSoonAs = function (func, condition) {
  var interval = setInterval(function () {
    var conditionResult = condition();
    if (
      typeof condition === 'function' &&
      typeof conditionResult === 'boolean' &&
      conditionResult
    ) {
      func();
      clearInterval(interval);
    }
  }, 0);
};
export const loadScript = (src = '') => {
  let element = document.getElementById('chat_bot_id');
  if (!element) {
    let head = document.getElementsByTagName('head');
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.id = 'chat_bot_id';
    head[0].appendChild(script);
  }
};
export const dateTimerFormat = date => {
  return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss a');
};

export const formatTime = (date, format = 'hh:mm a') => {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
};

export const dayAgo = date => {
  return moment(date)
    .fromNow()
    .replace('a few seconds', '1s')
    .replace(' minutes', 'min')
    .replace(' hours', 'hr');
};

export const calculateTimeDifferenceBetweenChat = (
  firstMsgCreatedOn,
  selectedChatCreatedOn,
  format = ''
) => {
  if (!firstMsgCreatedOn || !selectedChatCreatedOn) {
    return '';
  }
  let _firstMsg = moment(firstMsgCreatedOn);
  let _selectedChatCreatedOn = moment(selectedChatCreatedOn);
  let duration = moment.duration(_firstMsg.diff(_selectedChatCreatedOn));
  let inSeconds = parseInt(duration.asSeconds());
  if (format && inSeconds >= 0) {
    return format.replace('format', inSeconds);
  }
  return inSeconds;
};

export const parseFromJSON = data => {
  if (typeof data === 'string') return JSON.parse(data);
  else return data;
};

export const abbrNum = (number, decPlaces) => {
  decPlaces = Math.pow(10, decPlaces);
  const abbrev = ['K', 'M', 'B', 'T'];

  for (let i = abbrev.length - 1; i >= 0; i--) {
    let size = Math.pow(10, (i + 1) * 3);

    if (size <= number) {
      number = Math.round((number * decPlaces) / size) / decPlaces;

      number += abbrev[i];

      break;
    }
  }

  return number;
};

export const formatFileSize = sizeInBytes => {
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} B`; // Bytes
  } else if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(2)} KB`; // Kilobytes
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`; // Megabytes
  } else {
    return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`; // Gigabytes
  }
};

export const changeTourGuideFlag = () => {
  let loginUser = JSON.parse(storage.getItem('ViewedUser'));
  let profile = JSON.parse(storage.getItem('Profile'));
  if (loginUser) {
    const objIndex = loginUser.findIndex(obj => obj.userId === profile.UserID);
    loginUser[objIndex].isLogginBefore = true;
    storage.setItem('ViewedUser', loginUser);
  }
};

export const truncateText = (data, limit) => {
  let text = data;
  if (text) {
    text = text.toString();
    text = text.trim();
    if (text.length > limit) text = text.substring(0, limit).trim() + '...';
  }
  return text;
};

export const toolTipPlacement = window.innerWidth <= 1100 ? 'bottom' : 'left';

export const insertInArray = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
];

export const getUserTimezoneOffset = () => {
  let today = new Date();
  return today.getTimezoneOffset();
};

export const getUserTimezone = () => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: 'long' });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);

    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    let tzone = trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    return tzone in DaylightTimezones ? DaylightTimezones[tzone] : tzone;
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

export const addSubdomainInURL = (url, subDomain) => {
  return url.replace(
    /^(http|https):\/\//,
    (url.indexOf('https') === -1 ? 'http' : 'https') + '://' + subDomain + '.'
  );
};

export const updateViewedUser = value => {
  let ViewedUsers = JSON.parse(storage.getItem('ViewedUser'));
  let profile = JSON.parse(storage.getItem('Profile'));
  if (ViewedUsers) {
    const objIndex = ViewedUsers.findIndex(
      obj => obj.userId === profile?.UserID
    );
    if (objIndex >= 0) {
      ViewedUsers[objIndex] = { ...ViewedUsers[objIndex], ...value };
    } else {
      let user = { userId: profile?.UserID, ...value };
      ViewedUsers.push(user);
    }
    storage.setItem('ViewedUser', ViewedUsers);
  } else {
    let user = { userId: profile?.UserID, ...value };
    storage.setItem('ViewedUser', [user]);
  }
};

export const getUserFromViewedUser = () => {
  let ViewedUsers = JSON.parse(storage.getItem('ViewedUser'));
  let profile = JSON.parse(storage.getItem('Profile'));
  let user = null;
  if (ViewedUsers) {
    const objIndex = ViewedUsers.findIndex(
      obj => obj.userId === profile?.UserID
    );
    if (objIndex >= 0) {
      user = ViewedUsers[objIndex];
    }
  }

  return user;
};

/**
 * @name @addPrefixToObjectKeys
 * @description add prefix to every key of the provided object
 * @requires Required first argument as object which keys to be updated
 *           prefix string to be added
 * @returns updated object
 * */

export const addPrefixToObjectKeys = (obj, prefix) => {
  let newObject = {};
  Object.keys(obj).forEach(key => {
    newObject[prefix + key] = obj[key];
  });

  return newObject;
};

// Get Initials of String //
export const getStringInitials = name => {
  let splitted = name?.split(' ');
  let Initials = '';
  splitted?.map(
    item => (Initials = `${Initials}${item ? item[0].toUpperCase() : ''}`)
  );
  return Initials;
};

export const getEnumTextByValue = (enumObj, value) => {
  for (var k in enumObj) if (enumObj[k] === value) return k;
  return null;
};

export const jsonParser = data => {
  if (data) return JSON.parse(data);
  else return data;
};

export const checkAuthToken = () => {
  let isAuth = !!getCookie('Auth');
  return isAuth;
};

export const minutesToDuration = minutes => {
  const hours = Math.floor(minutes / 60);
  const mins = Math.floor(minutes % 60);
  const secs = Math.round((minutes % 1) * 60);

  // Format as "HH:mm:ss"
  return moment
    .utc()
    .hours(hours)
    .minutes(mins)
    .seconds(secs)
    .format('HH:mm:ss');
};
export const generateDurationOptions = (customOptions = []) => {
  let defaultOptions = { ...Enums.durations };
  if (customOptions.length)
    Object.keys(defaultOptions).forEach(key => {
      if (!customOptions.includes(defaultOptions[key])) {
        delete defaultOptions[key];
      }
    });
  return defaultOptions;
};

// download file
export const downloadFile = (file, fileName, format) => {
  const url = window.URL.createObjectURL(
    new Blob([file], { type: `application/${format}` })
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.${format}`); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const checkValue = value => {
  if (value === '') {
    return null;
  } else {
    return value;
  }
};

// Converte file JSON to CSV and download it
export const JSONToCSVFileConvertor = (JSONData, ReportTitle, colsDetail) => {
  // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
  var CSV = '';
  // Set Report title in first row or line
  CSV += ReportTitle + '\r\n\n';
  // This  will generate the Label/Header
  let row = '';
  // This loop will extract the label from 1st index of on array
  if (arrData[0] && colsDetail.length > 0) {
    //checking JSON have data and columns include in export
    for (let i = 0; i < colsDetail.length; i++) {
      row += colsDetail[i].header + ',';
    }
  }
  // remove the last comma
  row = row.slice(0, -1);
  // append Label row with line break
  CSV += row + '\r\n';
  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = '';
    // 2nd loop will extract each column and convert it in string comma-separated
    for (var index = 0; index < colsDetail.length; index++) {
      if (
        colsDetail[index].type === 'date' ||
        colsDetail[index].filterType === 'date'
      ) {
        var dateValue = arrData[i][colsDetail[index].name];
        if (dateValue) {
          var regexMatches = dateValue.match(dateRegexwithNegativesign);
          if (regexMatches.length > 1) {
            var dateTicks = regexMatches[1];
            if (parseInt(dateTicks, 10) < 0) {
              row += '"N/A",';
              continue;
            }
          }
        }
        row += '"' + longDateRenderer(dateValue) + '",';
      } else {
        let key = colsDetail[index].name;
        if (key === 'id') {
          row += '"' + (arrData[i][key] || arrData[i]['Id']) + '",';
        } else {
          row += '"' + checkValue(arrData[i][key]) + '",';
        }
      }
    }
    row.slice(0, row.length - 1);
    // add a line break after each row
    CSV += row + '\r\n';
  }

  if (CSV === '') {
    alert('Invalid data');
    return;
  }
  //this will remove the blank-spaces from the title and replace it with an underscore
  var fileName = ReportTitle.replace(/ /g, '_');
  //Initialize file format you want csv or xls
  var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension
  // this trick will generate a temp <a /> tag
  var link = document.createElement('a');
  link.href = uri;
  // set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden';
  link.download = fileName + '.csv';
  // this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const dateRegexwithNegativesign = /\/Date\((-?\d+[\-|\+]?\d{0,4})\)\//;
const dateRegex = /\/Date\(-?(\d+[\-|\+]?\d{0,4})\)\//;

const longDateRenderer = value => {
  if (value && typeof value.getDate === 'undefined') {
    value = parseDate(value);
  }
  if (value) {
    return moment(value).format('DD/MM/YY h:m:s ZZ');
  }
  return value === null ? null : value;
};

const parseDate = function (value) {
  if (!value) {
    return value;
  }
  if (typeof value.getDate !== 'undefined') {
    return value;
  }
  var ticksAndZone = value.match(dateRegex)[1];
  var delim = ticksAndZone.indexOf('-') > -1 ? '-' : '+';
  var parts = ticksAndZone.split(delim);
  var ticks = parseInt(parts[0], 10);
  return new Date(ticks);
};

export const isObjectEmpty = obj => {
  let isEmpty = Object.keys(obj).length ? false : true;
  return isEmpty;
};

export const tryParseJson = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

export const RandomNumber = () => {
  return Date.now() + Math.floor(Math.random() * 100);
};
export const getApexchatOwnerKeys = config.apexchatOwnerCompanyKeys.split(',');

export const getReachlocalOwnerKeys =
  config.hideSectionSettingsForOwnerCompanyKeys.split(',');

export const getTimeDifference =
  (startTime = moment()) =>
  (endTime = moment()) => {
    let difference = endTime.diff(startTime, 'seconds');
    console.log(difference);
    return difference;
  };

export const isInViewport = elem => {
  const rect = elem.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const ToastTemplate = {
  success: val => getTemplate('Success', val),
  error: val => getTemplate('Error', val),
  info: val => getTemplate('Info', val),
  warning: val => getTemplate('Warning', val),
  custom: (title, text) => getTemplate(title, text)
};
const getTemplate = (title, msg) => (
  <div style={{ lineHeight: '1.3' }}>
    <b>{title}</b>
    <div>{msg}</div>
  </div>
);

// create url after SSO redirection for specific page
export const createReturnUrl = (path = '') => {
  return path;
};

export const getQueryStringVariables = (path, key) => {
  return unescape(
    path.replace(
      new RegExp(
        '^(?:.*[&\\?]' +
          escape(key).replace(/[\.\+\*]/g, '\\$&') +
          '(?:\\=([^&]*))?)?.*$',
        'i'
      ),
      '$1'
    )
  );
};

export const convertCustomerPortalLinkToLegacyPortal = (
  location,
  queryParam
) => {
  let path = location.pathname + location?.search;
  let id = getQueryStringVariables(path, queryParam);
  // /portal/leads?id=123 => /Customer.aspx?leadId=123
  if (path.includes('lead')) {
    // SSO-81 leads not includes in path and leadId queryParams not available
    if (!id) {
      id = getQueryStringVariables(path, 'leadId');
    }

    return `/Customer.aspx?leadId=${id}`;
  }
  // /portal/onboarding?id=123 => /portal.aspx#provisioningrequests/123
  if (path.includes('onboarding')) {
    return `/portal.aspx#provisioningrequests/${id}`;
  }
  // /portal/chats?id=123 => /portal.aspx?chatId=123
  if (path.includes('chats')) {
    return `/portal.aspx?chatId=${id}`;
  }

  return location?.hash
    ? `/portal.aspx${location?.hash}`
    : `/portal.aspx?${location?.search}`;
};

export const convertLegacyPortalLinkToCustomerPortal = (
  location,
  queryParam
) => {
  let path = location?.pathname + (location?.hash || location?.search);
  // /Customer.aspx?leadId=123 => /portal/leads?id=123
  if (path.toLowerCase().includes('customer.aspx?leadid')) {
    let id = getQueryStringVariables(path, 'leadId');
    return `/portal/leads?id=${id}`;
  }
  // /portal.aspx#provisioningrequests/123 => /portal/onboarding?id=123
  if (path.includes('#provisioningrequests/')) {
    let id = path.split('provisioningrequests/')[1];
    return `/portal/onboarding?id=${id}`;
  }
  // /portal.aspx?chatId=123 => /portal/chats?id=123
  if (path.includes('portal.aspx?chatId')) {
    let id = getQueryStringVariables(path, 'chatId');
    return `/portal/chats?id=${id}`;
  }
  return path.includes('isCP=true') ? path : '/home';
};

export const isJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
