export const en_chatBotDetails = {
  getStarted: 'Get Started with AI powered Customer Service Bot',
  setupBot: 'Setting up your bot',
  botName: 'Bot Name',
  selectCompany: 'Select Company',
  industryType: 'Select Industry Type',
  subType: 'Select Sub Type',
  aboutBusiness: 'Please tell us about your business type',
  aboutBusinessHelper: 'This will help us generate industry specific content',
  websiteUrl: 'Provider website URL to import knowledge',
  websiteUrlHelper:
    'Our system will learn from website to answer related questions. It will take several minutes according to the length of website',
  provideUrl: 'Provide URL',
  addMoreUrl: 'Add more URL',
  next: 'Next',
  back: 'Back',
  managesettings: 'Manage Settings',
  gotointegrations: 'Go to Integrations',
  finish: 'Finish',
  createBot: 'Create Bot',
  extractedInfo: 'Extracted Information',
  fineTune: 'Fine-tune',
  uploadContent: 'Upload Content',
  step2Heading: 'Content extracted from your website',
  businessinfo: 'Company/Business Information',
  BusinessInformation: 'Business Information',
  Finetune: 'Fine-tune',
  ConversationalLogic: 'Conversational Logic',
  step2Detail:
    'Review the following extracted information. This information will help us auto-magically train the bot for you.',
  step3Heading: 'Fine-Tune your bot',
  step3Detail:
    'Based on your business type, we detected that following questions might be important for defining the chat flow. Please review and edit where necessary:',
  step4Heading: 'Upload your content',
  step4Detail:
    'This will help us train your chatbot with your uploaded documents',
  edit: 'Edit',
  delete: 'Delete'
};
export default en_chatBotDetails;
export { default as fr_chatBotDetails } from '../fr-FR-ChatBotDictionary/fr-chatBotDetails';
export { default as sp_chatBotDetails } from '../sp-SP-ChatBotDictionary/sp-chatBotDetails';
