export const sp_chatBotDetails = {
  getStarted: 'Comienza con un bot de servicio al cliente impulsado por IA',
  setupBot: 'Configurando tu bot',
  botName: 'Nombre del Bot',
  selectCompany: 'Seleccionar Empresa',
  industryType: 'Seleccionar tipo de industria',
  subType: 'Seleccionar subtipo',
  aboutBusiness: 'Por favor, dinos sobre el tipo de tu negocio',
  aboutBusinessHelper:
    'Esto nos ayudará a generar contenido específico para tu industria',
  websiteUrl: 'Proporciona la URL del sitio web para importar conocimiento',
  websiteUrlHelper:
    'Nuestro sistema aprenderá del sitio web para responder preguntas relacionadas. Esto puede tardar varios minutos dependiendo de la extensión del sitio web',
  provideUrl: 'Proporcionar URL',
  addMoreUrl: 'Agregar más URL',
  next: 'Siguiente',
  back: 'Atrás',
  finish: 'Finalizar',
  createBot: 'Crear Bot',
  extractedInfo: 'Información Extraída',
  fineTune: 'Ajustar',
  uploadContent: 'Subir Contenido',
  step2Heading: 'Contenido extraído de tu sitio web',
  step2Detail:
    'Revisa la siguiente información extraída. Esta información nos ayudará a entrenar el bot automáticamente para ti.',
  step3Heading: 'Ajusta tu bot',
  step3Detail:
    'Según el tipo de tu negocio, detectamos que las siguientes preguntas podrían ser importantes para definir el flujo del chat. Por favor, revisa y edita si es necesario:',
  step4Heading: 'Sube tu contenido',
  step4Detail:
    'Esto nos ayudará a entrenar tu chatbot con los documentos que subas.',
  edit: 'Editar',
  delete: 'Eliminar'
};
export default sp_chatBotDetails;
