export const fr_chatBotDetails = {
  getStarted: 'Commencez avec un bot de service client alimenté par l`IA',
  setupBot: 'Configuration de votre bot',
  botName: 'Nom du Bot',
  selectCompany: 'Sélectionner une Entreprise',
  industryType: 'Sélectionner le type d`industrie',
  subType: 'Sélectionner le sous-type',
  aboutBusiness: 'Veuillez nous parler de votre type d`entreprise',
  aboutBusinessHelper:
    'Cela nous aidera à générer un contenu spécifique à votre secteur',
  websiteUrl: 'Fournissez l`URL du site Web pour importer des connaissances',
  websiteUrlHelper:
    'Notre système apprendra du site Web pour répondre aux questions connexes. Cela prendra plusieurs minutes en fonction de la longueur du site Web',
  provideUrl: 'Fournir une URL',
  addMoreUrl: 'Ajouter plus d`URL',
  next: 'Suivant',
  back: 'Retour',
  finish: 'Terminer',
  createBot: 'Créer un Bot',
  extractedInfo: 'Informations Extraites',
  fineTune: 'Ajuster',
  uploadContent: 'Télécharger du Contenu',
  step2Heading: 'Contenu extrait de votre site web',
  step2Detail:
    'Examinez les informations extraites suivantes. Ces informations nous aideront à entraîner automatiquement le bot pour vous.',
  step3Heading: 'Affinez votre bot',
  step3Detail:
    'En fonction de votre type d`entreprise, nous avons détecté que les questions suivantes pourraient être importantes pour définir le flux de chat. Veuillez vérifier et modifier si nécessaire :',
  step4Heading: 'Téléchargez votre contenu',
  step4Detail:
    'Cela nous aidera à entraîner votre chatbot avec vos documents téléchargés.',
  edit: 'Éditer',
  delete: 'Supprimer'
};
export default fr_chatBotDetails;
