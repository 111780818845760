import { createSlice } from '@reduxjs/toolkit';
import ApiServices from 'api/apexchat.api.services';
import { ChatBotAPI } from 'api/endpoints';

/**
 * These function called thunk and allows to perform async logic
 * It can be dispatched like a regular action: `dispatch(data)`
 **/

export const chatbotSlice = createSlice({
  name: 'chatbot',
  initialState: {
    chatbot: null
  },

  reducers: {
    setChatBotToState: (state, action) => {
      state.chatbot = action.payload;
    }
  }
});

export const { setChatBotToState } = chatbotSlice.actions;
export default chatbotSlice.reducer;

export const selectChatBot = state => {
  return state.chatbot.chatbot;
};

export const getAppointments = () => {
  let endpoint = ChatBotAPI.appointments;
  return ApiServices.get(endpoint);
};

export const getAppointmentById = id => {
  let endpoint = ChatBotAPI.appointmentById(id);
  return ApiServices.get(endpoint);
};

export const createAppointment = payload => {
  let endpoint = ChatBotAPI.appointments;
  return ApiServices.post(endpoint, payload);
};

export const updateAppointment = (id, payload) => {
  let endpoint = ChatBotAPI.appointmentById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteAppointment = id => {
  let endpoint = ChatBotAPI.appointmentById(id);
  return ApiServices.delete(endpoint);
};

export const getCalls = () => {
  let endpoint = ChatBotAPI.calls;
  return ApiServices.get(endpoint);
};

export const getCallById = id => {
  let endpoint = ChatBotAPI.callById(id);
  return ApiServices.get(endpoint);
};

export const createCall = payload => {
  let endpoint = ChatBotAPI.calls;
  return ApiServices.post(endpoint, payload);
};

export const updateCall = (id, payload) => {
  let endpoint = ChatBotAPI.callById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteCall = id => {
  let endpoint = ChatBotAPI.callById(id);
  return ApiServices.delete(endpoint);
};

export const getChatbots = () => {
  let endpoint = ChatBotAPI.chatbots;
  return ApiServices.get(endpoint);
};

export const getChatbotById = id => {
  let endpoint = ChatBotAPI.chatbotById(id);
  return ApiServices.get(endpoint);
};

export const createChatbot = payload => {
  let endpoint = ChatBotAPI.chatbots;
  return ApiServices.post(endpoint, payload);
};

export const updateChatbot = (id, payload) => {
  let endpoint = ChatBotAPI.chatbotById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteChatbot = id => {
  let endpoint = ChatBotAPI.chatbotById(id);
  return ApiServices.delete(endpoint);
};

export const getSessionHistoryById = id => {
  let endpoint = ChatBotAPI.sessionHistory(id);
  return ApiServices.get(endpoint);
};

export const getWorkingDays = () => {
  let endpoint = ChatBotAPI.workingDays;
  return ApiServices.get(endpoint);
};

export const getWorkingDayById = id => {
  let endpoint = ChatBotAPI.workingDayById(id);
  return ApiServices.get(endpoint);
};

export const createWorkingDay = payload => {
  let endpoint = ChatBotAPI.workingDays;
  return ApiServices.post(endpoint, payload);
};

export const updateWorkingDay = (id, payload) => {
  let endpoint = ChatBotAPI.workingDayById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteWorkingDay = id => {
  let endpoint = ChatBotAPI.workingDayById(id);
  return ApiServices.delete(endpoint);
};

export const getLeads = () => {
  let endpoint = ChatBotAPI.leads;
  return ApiServices.get(endpoint);
};

export const getLeadById = id => {
  let endpoint = ChatBotAPI.leadById(id);
  return ApiServices.get(endpoint);
};

export const createLead = payload => {
  let endpoint = ChatBotAPI.leads;
  return ApiServices.post(endpoint, payload);
};

export const updateLead = (id, payload) => {
  let endpoint = ChatBotAPI.leadById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteLead = id => {
  let endpoint = ChatBotAPI.leadById(id);
  return ApiServices.delete(endpoint);
};

export const getQuestions = () => {
  let endpoint = ChatBotAPI.questions;
  return ApiServices.get(endpoint);
};

export const getQuestionById = id => {
  let endpoint = ChatBotAPI.questionById(id);
  return ApiServices.get(endpoint);
};

export const createQuestion = payload => {
  let endpoint = ChatBotAPI.questions;
  return ApiServices.post(endpoint, payload);
};

export const updateQuestion = (id, payload) => {
  let endpoint = ChatBotAPI.questionById(id);
  return ApiServices.post(endpoint, payload);
};

export const deleteQuestion = id => {
  let endpoint = ChatBotAPI.questionById(id);
  return ApiServices.delete(endpoint);
};
