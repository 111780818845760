import {
  en_basicDetails,
  sp_basicDetails,
  fr_basicDetails
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-basicDetails';
import {
  en_contactInformation,
  sp_contactInformation,
  fr_contactInformation
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-contactInformation';
import {
  en_crm,
  sp_crm,
  fr_crm
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-crm';
import {
  en_facebookIntegration,
  sp_facebookIntegration,
  fr_facebookIntegration
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-facebookIntegration';
import {
  en_hoursCallConnect,
  sp_hoursCallConnect,
  fr_hoursCallConnect
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-hoursCallConnect';
import {
  en_leadEmails,
  sp_leadEmails,
  fr_leadEmails
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-leadEmails';
import {
  en_mobileSettings,
  sp_mobileSettings,
  fr_mobileSettings
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-mobileSettings';
import {
  en_pacingLeadsAndTraffic,
  sp_pacingLeadsAndTraffic,
  fr_pacingLeadsAndTraffic
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-pacingLeadsAndTraffic';
import {
  en_trackingIntegration,
  sp_trackingIntegration,
  fr_trackingIntegration
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-trackingIntegration';
import {
  en_general,
  sp_general,
  fr_general
} from 'app/internationalization/Company-Dictionary/en-US-Dictionary/dictionary-general';
import {
  en_chatDetails,
  sp_chatDetails,
  fr_chatDetails
} from 'app/internationalization/Chat-Dictionary/en-US-ChatDictionary/dictionary-chatDetails';
import {
  en_leadDetails,
  sp_leadDetails,
  fr_leadDetails
} from 'app/internationalization/Lead-Dictionary/en-US-LeadDictionary/dictionary-leadDetails';
import {
  en_provisioningDetails,
  sp_provisioningDetails,
  fr_provisioningDetails
} from 'app/internationalization/Provisioning-Dictionary/en-US-ProvisioningDictionary/dictionary-provisioningDetails';

import {
  en_reportDetails,
  sp_reportDetails,
  fr_reportDetails
} from 'app/internationalization/Report-Dictionary/en-US-ReportDictionary/dictionary-reportDetails';

import {
  en_analytics,
  sp_analytics,
  fr_analytics
} from 'app/internationalization/Analytics-Dictionary/en-US-AnalyticsDictionary/dictionary-analytics';

import {
  en_tour,
  sp_tour,
  fr_tour
} from 'app/internationalization/Tour-Dictionary/en-US-TourDictionary/dictionary-Tour';
import {
  en_widget,
  sp_widget,
  fr_widget
} from 'app/internationalization/Widget-Dictionary/en-US-WidgetDictionary/dictionary-Widget';
import en_chatBotDetails, {
  fr_chatBotDetails,
  sp_chatBotDetails
} from './internationalization/ChatBot-Dictionary/en-US-ChatBotDictionary/dictionary-chatBotDetails';

const companyHelperText = {
  // In future we may fetch from API and modify this area
  en: {
    basicDetails: en_basicDetails,
    contactInformation: en_contactInformation,
    crm: en_crm,
    facebookIntegration: en_facebookIntegration,
    hoursCallConnect: en_hoursCallConnect,
    leadEmails: en_leadEmails,
    mobileSettings: en_mobileSettings,
    pacingLeadsAndTraffic: en_pacingLeadsAndTraffic,
    trackingIntegration: en_trackingIntegration,
    general: en_general,
    leadDetails: en_leadDetails,
    chatBotDetails: en_chatBotDetails,
    provisioningDetails: en_provisioningDetails,
    reportDetails: en_reportDetails,
    analytics: en_analytics,
    tour: en_tour
  },
  sp: {
    basicDetails: sp_basicDetails,
    contactInformation: sp_contactInformation,
    crm: sp_crm,
    facebookIntegration: sp_facebookIntegration,
    hoursCallConnect: sp_hoursCallConnect,
    leadEmails: sp_leadEmails,
    mobileSettings: sp_mobileSettings,
    pacingLeadsAndTraffic: sp_pacingLeadsAndTraffic,
    trackingIntegration: sp_trackingIntegration,
    general: sp_general,
    leadDetails: sp_leadDetails,
    chatBotDetails: sp_chatBotDetails,
    provisioningDetails: sp_provisioningDetails,
    reportDetails: sp_reportDetails,
    analytics: sp_analytics,
    tour: sp_tour
  },
  fr: {
    basicDetails: fr_basicDetails,
    contactInformation: fr_contactInformation,
    crm: fr_crm,
    facebookIntegration: fr_facebookIntegration,
    hoursCallConnect: fr_hoursCallConnect,
    leadEmails: fr_leadEmails,
    mobileSettings: fr_mobileSettings,
    pacingLeadsAndTraffic: fr_pacingLeadsAndTraffic,
    trackingIntegration: fr_trackingIntegration,
    general: fr_general,
    leadDetails: fr_leadDetails,
    chatBotDetails: fr_chatBotDetails,
    provisioningDetails: fr_provisioningDetails,
    reportDetails: fr_reportDetails,
    analytics: fr_analytics,
    tour: fr_tour
  }
};

const chatHelperText = {
  // In future we may fetch from API and modify this area
  en: {
    chatDetails: en_chatDetails
  },
  sp: {
    chatDetails: sp_chatDetails
  },
  fr: {
    chatDetails: fr_chatDetails
  }
};
const widgetHelperText = {
  // In future we may fetch from API and modify this area
  en: {
    ...en_widget
  },
  sp: {
    ...sp_widget
  },
  fr: {
    ...fr_widget
  }
};

/**
 * [Discarded]
 * @param {string} lang 'en', 'sp' etc.
 * @param {string} module what module you need, e.g: 'company', 'profile'
 * @param {string} property the property you need, 'basicDetails', 'tracking' etc.
 * @returns returns object of HelperText
 * //languageExtender('sp', companyHelperText)
 */
export const languageExtender = (lang, module) => {
  const helperTexts = module;
  if (lang !== 'en') {
    let clone = helperTexts[lang];
    for (let propName in helperTexts['en']) {
      clone[propName] = {
        ...helperTexts['en'][propName],
        ...helperTexts[lang][propName]
      };
    }
    return { ...helperTexts['en'], ...clone };
  }
  return helperTexts[lang];
};
/**
 * Whenever new module will be added, we may need to add the object here.
 */
export const LabelsNTooltips = {
  en: {
    company: companyHelperText.en,
    chat: chatHelperText.en,
    widget: widgetHelperText.en
  },
  sp: {
    company: companyHelperText.sp,
    chat: chatHelperText.sp,
    widget: widgetHelperText.sp
  },
  fr: {
    company: companyHelperText.fr,
    chat: chatHelperText.fr,
    widget: widgetHelperText.fr
  }
};

export const en = LabelsNTooltips.en;
export const sp = LabelsNTooltips.sp;
export const fr = LabelsNTooltips.fr;
